import { createContext, ReactNode, useCallback, useMemo, useState } from "react";
import { saveToDisk } from "../../utils/saveToDisk";
import { retrieveFromDisk } from "../../utils/retrieveFromDisk";

interface AuthStateValues {
  name: string;
  version: string;
  loggedIn: boolean;
  jwt: string | null;
}

interface AuthValues {
  state: AuthStateValues;
  logout: () => void;
  login: (jwt: string, name: string) => void;
}

const authStorageKey = "_authData";
const defaultState: AuthStateValues = {
  jwt: "",
  name: "",
  version: "1.0.0",
  loggedIn: false,
}

const getInitialState = (): AuthStateValues => {
  let _savedStateStr = retrieveFromDisk(authStorageKey)
  if (!_savedStateStr) return { ...defaultState }
  const _savedState = JSON.parse(_savedStateStr) as AuthStateValues
  if (_savedState.version !== defaultState.version) {
    // Version update occurred 
    return { ...defaultState }
  }
  return _savedState
}

const initialState = getInitialState()
const ctxDefaultValue: AuthValues = {
  state: initialState,
  login: () => undefined,
  logout: () => undefined,
};

export const AuthContext = createContext<AuthValues>(ctxDefaultValue);
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {

  const [state, setState] = useState(ctxDefaultValue.state);

  const logout = useCallback(() => setState(defaultState), []);
  const login = useCallback((jwt: string, name: string) => setState({ ...defaultState, jwt, name, loggedIn: true }), []);

  const value = useMemo(() => {
    saveToDisk(JSON.stringify(state), authStorageKey)
    return { state, login, logout }
  }, [state, login, logout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
