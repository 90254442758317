import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";
import { Item } from "../../../components/Layout/Item/Item";
import { Group } from "../../../components/Layout/Group/Group";

export const Footer = () => (
  <footer className={styles.footer}>
    <Group gap="sm">
      <Group gap="sm" direction="row" style={{ alignItems: "center" }}>
        <img
          src="/assets/images/jlr-logo.png"
          width="35"
          height="35"
          alt="JLR logo"
          style={{ opacity: 0.5 }}
        />

        <Item>
          <p>© JAGUAR LAND ROVER LIMITED 2023</p>
          <p>
            Jaguar Land Rover Limited: Registered office: Abbey Road, Whitley, Coventry CV3 4LF.
            Registered in England No: 1672070
          </p>
        </Item>
      </Group>

      <hr />

      <Item>
        <Link to="terms-and-conditions">Terms & Conditions</Link> &nbsp; / &nbsp;{" "}
        <a href="https://www.jaguarlandrover.com/privacy" target="_blank" rel="noreferrer">
          Privacy
        </a>{" "}
        &nbsp; / &nbsp; <Link to="about">About</Link> &nbsp; / &nbsp;{" "}
        <Link to="contact">Contact Us</Link>
      </Item>
    </Group>
  </footer>
);
