import { useCallback, useEffect, useRef } from "react";
import type { Notification } from "../../context/notification";
import { XIcon } from "../Icons/XIcon";
import styles from "./Notifications.module.scss";

const displayDuration = 5000;

const typeMap = {
  default: {
    style: "",
  },
  success: {
    style: styles.success,
  },
  error: {
    style: styles.error,
  },
};

interface NotificationItemProps {
  notification: Notification;
  onRemove: (id: string) => void;
}

export const NotificationItem = ({ notification, onRemove }: NotificationItemProps) => {
  const { id, type, title, body } = notification;
  const { style } = typeMap[type];

  const displayDurationTimeoutId = useRef<number>();

  const onMouseEnter = () => {
    clearTimeout(displayDurationTimeoutId.current);
  };

  const onMouseLeave = () => {
    displayDurationTimeoutId.current = window.setTimeout(removeItem, displayDuration / 2);
  };

  const removeItem = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  useEffect(() => {
    displayDurationTimeoutId.current = window.setTimeout(removeItem, displayDuration);
    return () => clearTimeout(displayDurationTimeoutId.current);
  }, [removeItem]);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${style} ${styles.notification}`}
      role="log"
    >
      <div>
        <h5 className={styles.title}>{title}</h5>
        {body && <p>{body}</p>}
      </div>

      <button
        onClick={removeItem}
        className={styles.close}
        title="Close Notification"
        aria-label="Close Notification"
      >
        <XIcon />
      </button>
    </div>
  );
};
