import { CSSProperties } from "react";
import styles from "./Spinner.module.scss";

interface SpinnerProps {
    show?: boolean;
    text?: string;
    style?: CSSProperties;
}

export const Spinner = ({ show = true, text = "PLEASE WAIT", style = {} }: SpinnerProps) => {
    if (!show) return null;

    return (
        <div className={styles.container} style={style}>
            <div className={styles.spinner} role="status">
                <div className={styles.text}>{text}</div>
            </div>
        </div>
    );
};
