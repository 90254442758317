import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Tooltip } from "react-tooltip";
import styles from "./Languages.module.scss";
import { FC, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button } from "../../components/Button";
import { useAPI } from "../../hooks/use-api.hook";
import { TextInput } from "../../components/Form";
import { XIcon } from "../../components/Icons/XIcon";
import { EditIcon } from "../../components/Icons/Edit";
import { TextIcon } from "../../components/Icons/Text";
import { useNotify } from '../../hooks/use-notify.hook';
import { Page } from "../../components/Layout/Page/Page";
import { ButtonIcon } from "../../components/Icons/Button";
import { Spinner } from "../../components/Spinner/Spinner";
import 'react-accessible-accordion/dist/fancy-example.css';
import { Group } from "../../components/Layout/Group/Group";
import { DismissIcon } from '../../components/Icons/Dismiss';
import { AddPageIcon } from '../../components/Icons/AddPage';
import { PlusCircleIcon } from "../../components/Icons/PlusCircle";
import { useNotification } from "../../hooks/use-notification.hook";
import { AllNotificationTextsResponse } from "../../types/all-notification-texts-response";
import { AppTypes, ElementTypes, NotificationWarningCodeBody, NotificationWarningCodeResponse } from "../../types/notification-warning-code-response";

interface LanguagesProp {
    appType: AppTypes
}

export const Languages: FC<LanguagesProp> = ({ appType }) => {

    const { confirm } = useNotify()
    const { notify } = useNotification();
    const { getAllNotificationTexts, setNotificationText, deleteNotificationWarningCode } = useAPI();
    const [updating, setUpdating] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const { data, isLoading, refetch } = useQuery({
        queryKey: [appType],
        queryFn: () => getAllNotificationTexts(appType as AppTypes),
    });
    const [highlightedWarningCode, setHighlightedWarningCode] = useState<NotificationWarningCodeResponse>()
    const [pages, setPages] = useState<Array<number>>([1])

    useEffect(() => {
        if (highlightedWarningCode && AppTypes.VEHICLE_HEALTH === appType) {
            let pageNumbers: Array<number> = [1]
            for (const { page } of highlightedWarningCode.body) {
                if (page && !pageNumbers.includes(page)) pageNumbers.push(page)
            }
            setPages(pageNumbers)
        }
    }, [highlightedWarningCode, appType])


    if (isLoading) return <Spinner text="Fetching Resources" style={{ margin: 120 }} />;
    const setBodyText = (text: string, index: number) => {
        if (highlightedWarningCode) {
            highlightedWarningCode.body[index].data = text
            const body = [...highlightedWarningCode.body]
            body[index].data = text
            setHighlightedWarningCode({
                ...highlightedWarningCode,
                body
            })
        }
    }

    const updateWarningCode = async () => {
        if (updating) return
        const patchBlank = async () => {
            if (!data || !highlightedWarningCode || !["en-us", "en-gb"].includes(highlightedWarningCode.locale.toLowerCase())) return
            const filteredNotificationTexts = data.filter(({ warningCodes }) => warningCodes.find(warningCodes => warningCodes.warningCode === highlightedWarningCode.warningCode && !warningCodes.title && !warningCodes.body.length))
            for (const filteredNotificationText of filteredNotificationTexts) {
                await setNotificationText(appType, filteredNotificationText.locale, highlightedWarningCode.warningCode, {
                    ...highlightedWarningCode,
                    locale: filteredNotificationText.locale,
                    warningCode: highlightedWarningCode.warningCode,
                })
            }
        }

        if (highlightedWarningCode && !updating) {
            let errors: string[] = []

            if (!highlightedWarningCode.title?.trim()) {
                errors.unshift(`Title field is required`);
            }
            for (let index = 0; index < highlightedWarningCode.body.length; index++) {
                if (!highlightedWarningCode.body[index].data?.trim()) {
                    errors.unshift(`Language field text is required on line ${index + 1}`);
                }
            }
            if (errors.length) {
                errors.map(error => notify({ type: "error", title: "Input Error!", body: error }))
                return
            }
            setUpdating(true)
            await Promise.all([
                setNotificationText(appType, highlightedWarningCode.locale, highlightedWarningCode.warningCode, highlightedWarningCode),
                patchBlank()
            ])
            notify({ type: "success", title: "Success!", body: `${highlightedWarningCode.locale} / ${highlightedWarningCode.warningCode} updated successfully.` });
            setUpdating(false)
            refetch()
        }
    }

    const deleteWarningCode = async ({ locale, warningCode }: NotificationWarningCodeResponse) => {
        if (updating) return
        setUpdating(true)
        await deleteNotificationWarningCode(appType as AppTypes, locale, warningCode)
        notify({ type: "success", title: "Success!", body: `${locale} / ${warningCode} deleted successfully.` });
        setUpdating(false)
        if (highlightedWarningCode?.warningCode === warningCode) setHighlightedWarningCode(undefined)
        refetch()
    }

    const addItemToBody = (type: ElementTypes, page: number) => {
        if (highlightedWarningCode) {
            const newItem: NotificationWarningCodeBody = { type, data: "", page };
            if (appType !== AppTypes.VEHICLE_HEALTH) {
                delete newItem.page;
            }
            const body = [...highlightedWarningCode.body, newItem]
            setHighlightedWarningCode({
                ...highlightedWarningCode,
                body
            })
        }
    }

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Page backBtn={true} actions={<h5>{appType === AppTypes.AGENT ? 'ONBOARD AGENT' : 'VEHICLE HEALTH'}</h5>}>
            <Group direction="row" gap="md">
                {
                    !!data && <div style={{ flex: 1 }} className={styles.slideInLeft}>
                        <NewWarningCode
                            onSuccess={refetch}
                            existingNotificationsTexts={data}
                            appType={appType}
                            onSearchQueryChange={(query) => setSearchQuery(query)}
                        />
                        <hr />
                        <Accordion allowZeroExpanded={true}>
                            {
                                data?.map((item, index) => (!searchQuery.trim() || JSON.stringify(item).toLowerCase().includes(searchQuery.trim().toLowerCase())) && <AccordionItem key={index}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <span>{item.locale} {!!item.country && <small> - <b>{item.country}</b></small>}</span>
                                            <small style={{ float: 'right' }}>{item.warningCodes.length} warning code(s)</small>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <Group gap='xs'>
                                            {
                                                item.warningCodes.map((warningCode, index) => <Group className={`${styles.itemPanel} ${highlightedWarningCode?.warningCode === warningCode.warningCode ? styles.active : ''}`} key={index} direction='row'>
                                                    <Group align="center" onClick={() => { setHighlightedWarningCode(warningCode); handleScrollToTop() }} direction="row" className={styles.warningCodeParent}
                                                        justify="space-between">
                                                        <p>{warningCode.warningCode}</p>
                                                        <EditIcon />
                                                    </Group>
                                                    <span className={styles.deleteContainer}
                                                        onClick={() => confirm({ onConfirmation: () => deleteWarningCode(warningCode) })}
                                                    >
                                                        <XIcon />
                                                    </span>
                                                </Group>)
                                            }
                                        </Group>
                                    </AccordionItemPanel>
                                </AccordionItem>)
                            }
                        </Accordion>
                    </div>
                }

                {
                    !!highlightedWarningCode && <div style={{ flex: 1 }} className={styles.slideInRight}>
                        <p><b>{highlightedWarningCode.locale}</b><span style={{ margin: '0 10px' }}>/</span><span>{highlightedWarningCode.warningCode}</span></p>
                        <br />
                        <TextInput
                            label="Title"
                            placeholder="Title"
                            isRequired
                            type="text"
                            maxLength={45}
                            value={highlightedWarningCode.title}
                            onChange={(title) => setHighlightedWarningCode({ ...highlightedWarningCode, title })}
                        />

                        {
                            appType === AppTypes.VEHICLE_HEALTH && <>
                                <br />
                                <Button
                                    text="Add page" loading={false}
                                    disabled={false}
                                    color='success'
                                    icon={<AddPageIcon />}
                                    style={{
                                        height: '35px'
                                    }} onClick={() => setPages([...pages, (pages.length + 1)])} />
                            </>
                        }

                        {
                            pages.map(page => <div key={page}>
                                <br />
                                {
                                    appType === AppTypes.VEHICLE_HEALTH && <>
                                        <h6>Page {page}</h6>
                                        <hr />
                                    </>
                                }

                                <Group direction="row" gap="xs" align="center" className={styles.toolbar}>
                                    <small>Add</small>
                                    <div data-tooltip-id="addTextTooltip" onClick={() => addItemToBody(ElementTypes.TEXT, page)}>
                                        <TextIcon />
                                    </div>
                                    <div data-tooltip-id="addButtonTooltip" className={highlightedWarningCode.body.find(({ type, page: _page }) => type === ElementTypes.CTA && (appType === AppTypes.AGENT || page === _page)) ? styles.disabled : ""} onClick={() => addItemToBody(ElementTypes.CTA, page)}>
                                        <ButtonIcon />
                                    </div>
                                    <div data-tooltip-id="addDismissTooltip" className={highlightedWarningCode.body.find(({ type, page: _page }) => type === ElementTypes.DISMISS && (appType === AppTypes.AGENT || page === _page)) ? styles.disabled : ""} onClick={() => addItemToBody(ElementTypes.DISMISS, page)}>
                                        <DismissIcon />
                                    </div>
                                </Group>
                                {
                                    !!highlightedWarningCode.body.length && <>
                                        {

                                            !!highlightedWarningCode.body.filter(body => appType !== AppTypes.VEHICLE_HEALTH || body.page === page).length && <>

                                                <Group gap="sm" style={{ marginTop: '10px' }} className={styles.content}>
                                                    {
                                                        highlightedWarningCode.body.map((body, index) => appType !== AppTypes.VEHICLE_HEALTH || body.page === page ? <Group align="center" gap="xs" direction="row" key={index}>
                                                            <div style={{ flex: 1 }}>
                                                                {
                                                                    body.type === ElementTypes.TEXT && <TextInput
                                                                        label="Text"
                                                                        placeholder="Text"
                                                                        type="text"
                                                                        isRequired
                                                                        style={{ fontSize: '14px' }}
                                                                        maxLength={300}
                                                                        value={body.data}
                                                                        onChange={(data) => setBodyText(data, index)}
                                                                    />
                                                                }
                                                                {
                                                                    body.type === ElementTypes.CTA && <TextInput
                                                                        label="CTA Text"
                                                                        placeholder="CTA Text"
                                                                        type="text"
                                                                        isRequired
                                                                        style={{ fontSize: '14px' }}
                                                                        maxLength={30}
                                                                        value={body.data}
                                                                        onChange={(data) => setBodyText(data, index)}
                                                                    />
                                                                }
                                                                {
                                                                    body.type === ElementTypes.DISMISS && <TextInput
                                                                        label="Dismiss Text"
                                                                        placeholder="Dismiss Text"
                                                                        type="text"
                                                                        isRequired
                                                                        style={{ fontSize: '14px' }}
                                                                        maxLength={30}
                                                                        value={body.data}
                                                                        onChange={(data) => setBodyText(data, index)}
                                                                    />
                                                                }
                                                            </div>
                                                            {
                                                                highlightedWarningCode.body.filter(body => appType !== AppTypes.VEHICLE_HEALTH || body.page === page).length !== 1 && <div data-tooltip-id="deleteTooltip" className={styles.deleteIcon} onClick={() => { highlightedWarningCode.body.splice(index, 1); setHighlightedWarningCode({ ...highlightedWarningCode }) }}>
                                                                    <XIcon />
                                                                </div>
                                                            }
                                                        </Group> : null)
                                                    }
                                                </Group>
                                            </>
                                        }
                                        {
                                            page === pages.length && <>
                                                <br />
                                                <Button text="Update" loading={updating} onClick={updateWarningCode} />
                                            </>
                                        }
                                    </>
                                }
                            </div>)
                        }
                    </div>
                }
            </Group>

            <Tooltip
                id="addTextTooltip"
                place="left"
                content="Add notification text"
                style={{ backgroundColor: "white", color: "#252525" }}
            />
            <Tooltip
                id="addButtonTooltip"
                place="top"
                content="Add Call-To-Action"
                style={{ backgroundColor: "white", color: "#252525" }}
            />
            <Tooltip
                id="addDismissTooltip"
                place="right"
                content="Add dismiss text"
                style={{ backgroundColor: "white", color: "#252525" }}
            />
            <Tooltip
                id="deleteTooltip"
                place="right"
                content="Remove item"
                style={{ backgroundColor: "white", color: "#252525" }}
            />
        </Page>
    );
};

const NewWarningCode: FC<{
    appType: AppTypes;
    onSuccess?: () => void;
    onSearchQueryChange?: (query: string) => void;
    existingNotificationsTexts: AllNotificationTextsResponse[];
}> = ({ existingNotificationsTexts, appType, onSuccess, onSearchQueryChange }) => {

    const { notify } = useNotification()
    const [query, setQuery] = useState("")
    const [open, setOpen] = useState(false)
    const [newWarningCode, setNewWarningCode] = useState<{ warningCode: string; processing: boolean; }>({ warningCode: "", processing: false })
    const { setNotificationText } = useAPI();

    const createNewWarningCode = async () => {
        if (newWarningCode.processing) return
        if (!newWarningCode.warningCode?.trim()) {
            setOpen(false)
            return
        }

        const filteredNotificationTexts = existingNotificationsTexts.filter(({ warningCodes }) => !warningCodes.find(warningCodes => warningCodes.warningCode === newWarningCode.warningCode))

        if (!filteredNotificationTexts.length) {
            notify({ type: "error", title: "Invalid warning code!", body: `${newWarningCode.warningCode} already exist.` });
            return
        }
        setNewWarningCode({ ...newWarningCode, processing: true })
        for (const filteredNotificationText of filteredNotificationTexts) {
            await setNotificationText(appType, filteredNotificationText.locale, newWarningCode.warningCode, {
                locale: filteredNotificationText.locale,
                warningCode: newWarningCode.warningCode,
                title: "",
                body: []
            })
        }

        notify({ type: "success", title: "Success!", body: `${newWarningCode.warningCode} created successfully.` });
        setOpen(false)
        setNewWarningCode({ ...newWarningCode, warningCode: "", processing: false })
        if (onSuccess) {
            onSuccess()
        }
    }

    return (
        <div className={styles.newWarningCodeWrapper}>
            {
                open ? <Group direction="row" gap="sm" align="center" justify="left">
                    <TextInput
                        placeholder="New warning code"
                        type="text"
                        maxLength={5}
                        readonly={newWarningCode.processing}
                        parentStyle={{ height: '35px' }}
                        value={newWarningCode.warningCode}
                        onChange={(warningCode) => setNewWarningCode({ ...newWarningCode, warningCode })}
                    />
                    <Button
                        text="Create" loading={newWarningCode.processing}
                        disabled={newWarningCode.processing}
                        icon={<PlusCircleIcon />}
                        style={{
                            height: '35px'
                        }} onClick={createNewWarningCode} />
                    <span className="pointer" onClick={() => setOpen(false)}><XIcon /></span>
                </Group> :
                    <Group direction="row" justify="space-between" align="center">
                        <TextInput
                            placeholder="Search"
                            type="search"
                            parentStyle={{ height: '35px', width: '220px' }}
                            value={query}
                            onKeyUp={(v) => { setQuery(v); onSearchQueryChange && onSearchQueryChange(v) }}
                            onChange={(v) => { setQuery(v); onSearchQueryChange && onSearchQueryChange(v) }}
                        />
                        <span>/</span>
                        <Button
                            text="New warning-code" loading={newWarningCode.processing}
                            disabled={newWarningCode.processing || !existingNotificationsTexts.length}
                            icon={<PlusCircleIcon />}
                            style={{
                                height: '35px'
                            }} onClick={() => setOpen(true)} />
                    </Group>
            }
        </div>
    )
}