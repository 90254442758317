import { Navigate } from "react-router-dom";
import { ReactElement, useContext } from "react";
import { AuthContext } from "../../../context/auth";

interface PublicRouteProps {
    children: ReactElement;
}

export const PublicRoute = ({ children }: PublicRouteProps) => {
    const { state } = useContext(AuthContext);

    if (state.loggedIn) {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
};
