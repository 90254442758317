export const VehicleHealthIcon = () => {
    return (
        <svg fill="#bab1a5" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M848.745 112.64h61.44c5.657 0 10.24 4.583 10.24 10.24v61.44c0 67.865-55.015 122.88-122.88 122.88h-112.64c-67.865 0-122.88-55.015-122.88-122.88v-61.44c0-5.657 4.583-10.24 10.24-10.24h61.44c11.311 0 20.48-9.169 20.48-20.48s-9.169-20.48-20.48-20.48h-61.44c-28.278 0-51.2 22.922-51.2 51.2v61.44c0 90.486 73.354 163.84 163.84 163.84h112.64c90.486 0 163.84-73.354 163.84-163.84v-61.44c0-28.278-22.922-51.2-51.2-51.2h-61.44c-11.311 0-20.48 9.169-20.48 20.48s9.169 20.48 20.48 20.48z"></path>
                <path d="M725.865 343.04v423.936c0 28.113-23.087 51.2-51.2 51.2h-81.92c-11.311 0-20.48 9.169-20.48 20.48s9.169 20.48 20.48 20.48h81.92c50.735 0 92.16-41.425 92.16-92.16V343.04c0-11.311-9.169-20.48-20.48-20.48s-20.48 9.169-20.48 20.48z"></path>
                <path d="M572.265 834.56c0-42.418-34.382-76.8-76.8-76.8s-76.8 34.382-76.8 76.8 34.382 76.8 76.8 76.8 76.8-34.382 76.8-76.8zm40.96 0c0 65.04-52.72 117.76-117.76 117.76s-117.76-52.72-117.76-117.76 52.72-117.76 117.76-117.76 117.76 52.72 117.76 117.76zM490.194 349.189c55.982 55.982 55.982 146.752 0 202.747L338.13 703.989c-19.989 19.989-52.412 19.989-72.401 0L113.663 551.933c-55.981-55.993-55.981-146.762.003-202.746 51.387-51.376 132.065-55.602 188.263-12.676 56.198-42.926 136.876-38.7 188.265 12.678zm-202.746 28.963c-39.987-39.987-104.824-39.987-144.82 0-39.987 39.987-39.987 104.824 0 144.819l152.064 152.054c3.993 3.993 10.482 3.993 14.475 0l152.062-152.052c39.989-39.997 39.989-104.835.003-144.82-39.997-39.989-104.835-39.989-144.821-.002-7.998 7.998-20.965 7.998-28.963 0z"></path>
            </g>
        </svg>
    );
};
