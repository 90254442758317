import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import styles from "./Navbar.module.scss";
import { LogOutIcon } from "../../Icons/LogOut";

interface MenuLink {
  to: string;
  name: string;
}

const menuLinks: MenuLink[] = [];

export const Navbar = () => {
  const { logout, state } = useContext(AuthContext);

  return (
    <nav className={styles.navMenu}>
      <div className={styles.inner}>
        <Link to="/" className={styles.navLogo} role="logo">
          <img src="/assets/images/jlr-logo.png" width="100%" alt="JLR logo" />
        </Link>

        {state.loggedIn && (
          <ul>
            {menuLinks.map(({ to, name }) => {
              return (
                <li key={to}>
                  <NavLink to={to} className={({ isActive }) => (isActive ? styles.active : "")}>
                    {name}
                  </NavLink>
                </li>
              );
            })}

            <li className={styles.logOut}>
              <button title="Log Out" className="btn-icon" onClick={logout}>
                <LogOutIcon />
              </button>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};
