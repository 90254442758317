import { useFetch } from "./use-fetch";
import { sortAlphabetically } from "../utils/sortAlphabetically";
import { AllNotificationTextsResponse } from "../types/all-notification-texts-response";
import { AppTypes, NotificationWarningCodeResponse } from "../types/notification-warning-code-response";

const baseEndPoint = "https://translations-api.dev.jlr-vcdp.com/v1";

interface GetAllNotificationTextsResponse {
    statusMessage: string;
    data: AllNotificationTextsResponse[];
}

interface SetNotificationTextResponse {
    statusMessage: string;
    data: NotificationWarningCodeResponse;
}

export const useAPI = () => {
    const { get, post, remove } = useFetch();

    const getAllNotificationTexts = async (appType: AppTypes) => {
        const { data: _data } = await get<GetAllNotificationTextsResponse>(`${baseEndPoint}/${appType}`);
        //sort
        for (const data of _data) {
            data.warningCodes = sortAlphabetically<NotificationWarningCodeResponse>(data.warningCodes, "warningCode")
        }

        let data = sortAlphabetically<AllNotificationTextsResponse>(_data, "locale");
        for (const locale of ["en-au", "en-us", "en-gb"]) {
            const index = data.findIndex(({ locale: _locale }) => _locale.toLowerCase() === locale)
            if (index > -1) {
                const item = data[index]
                data.splice(index, 1)
                data.unshift(item)
            }
        }
        return data;
    };

    const setNotificationText = async (appType: AppTypes, locale: string, warningCode: string, payload: NotificationWarningCodeResponse) => {
        const { data } = await post<SetNotificationTextResponse>(`${baseEndPoint}/${appType}/${locale}/${warningCode}`, payload);
        return data;
    };

    const deleteNotificationWarningCode = async (appType: AppTypes, locale: string, warningCode: string) => {
        const { data } = await remove<boolean>(`${baseEndPoint}/${appType}/${locale}/${warningCode}`);
        return data;
    };

    return { getAllNotificationTexts, setNotificationText, deleteNotificationWarningCode };
};
