import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styles from "./Dashboard.module.scss";

interface CardProps {
  title: string;
  desc: string;
  link: string;
  icon: ReactElement;
}

export const Card = ({ title, desc, link, icon }: CardProps) => {
  return (
    <Link to={link}>
      <div className={styles.box}>
        {icon}
        <p>{title}</p>
        <p className={styles.desc}>{desc}</p>
      </div>
    </Link>
  );
};
