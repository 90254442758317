import { useContext, useMemo } from "react";
import { AuthContext } from "../../context/auth";
import { Get, Post, Remove, Write } from "./types";
import { parseResponse } from "./common";

export const useFetch = () => {
    const { state, logout } = useContext(AuthContext);

    const headers = useMemo(
        () => ({
            headers: {
                Authorization: `Bearer ${state.jwt}`,
                "Content-Type": "application/json",
            },
        }),
        [state]
    );

    const write: Write = async (method, url, payload, customOptions = {}) => {
        const options = {
            method,
            body: JSON.stringify(payload),
            ...headers,
            ...customOptions,
        };
        const response = await fetch(url, options);
        const { status, statusText } = response;
        if (![200, 202, 204].includes(status)) {
            if (401 === status) {
                logout()
                throw new Error("Session expired!");
            }
            throw new Error(statusText);
        }
        return parseResponse(response);
    };

    const get: Get = async (url, customOptions = {}) => {
        const options = { ...headers, ...customOptions };
        let response: Response;
        try {
            response = await fetch(url, options);
            const data = await response.json();
            return data;
        } catch (error) {
            if (error instanceof Response) {
                // Access the status code from the Response object
                const { status, statusText } = error;
                if (![200, 202, 204].includes(status)) {
                    if (401 === status) {
                        logout()
                        throw new Error("Session expired!");
                    }
                    throw new Error(statusText);
                }
            } else {
                logout()
                throw new Error("Session expired!");
            }
        }

    };

    const post: Post = (url, payload, customOptions = {}) => {
        return write("POST", url, payload, customOptions);
    };

    const patch: Post = (url, payload, customOptions = {}) => {
        return write("PATCH", url, payload, customOptions);
    };

    const remove: Remove = (url, payload = {}, customOptions = {}) => {
        return write("DELETE", url, payload, customOptions);
    };

    const fetchFile = async (url: string) => {
        const response = await fetch(url, headers);
        const { status, statusText } = response;
        if (![200, 202, 204].includes(status)) {
            throw new Error(statusText);
        }
        return response;
    };

    return { get, patch, post, remove, fetchFile };
};
