export const retrieveFromDisk = (storageKey: string): string | null => {
    try {
        return localStorage.getItem(storageKey);
    } catch (error) {
        // Local storage is not allowed in cognito mode, try session storage instead
    }
    try {
        return sessionStorage.getItem(storageKey);
    } catch (error) {
        // Local storage is not allowed in cognito mode, try session storage instead
    }
    return null
}