import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { AuthContext } from "../../context/auth";
import { useNotification } from "../../hooks/use-notification.hook";
import { Group } from "../../components/Layout/Group/Group";
import { TextInput } from "../../components/Form";
import { Button } from "../../components/Button";

export const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { notify } = useNotification();
  const [isLoading, setIsloading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFirstLogin, setFirstLogin] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userAttributes, setUserAttributes] = useState({});
  const [cognitoObj, setCognitoObj] = useState<CognitoUser>();

  const authenticate = () => {
    setIsloading(true);

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const userPool = new CognitoUserPool({
      UserPoolId: "eu-west-2_gN4HW0Lyl",
      ClientId: "538n8nua33sr0tomqu016q8h83",
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        const token = result.getIdToken().getJwtToken();
        onSuccess(token, cognitoUser);
      },

      newPasswordRequired(userAttr) {
        delete userAttr.email_verified;
        delete userAttr.phone_number_verified;
        delete userAttr.email;

        userAttr.name = userAttr.name ? userAttr.name : "User";

        setFirstLogin(true);
        setUserAttributes(userAttr);
        setCognitoObj(cognitoUser);
      },

      onFailure(err) {
        notify({ type: "error", title: "Login Error!", body: err.message });
        setIsloading(false);
      },
    });
  };

  const newPasswordHandler = () => {
    if (!cognitoObj) return;

    if (newPassword !== confirmPassword) {
      notify({
        type: "error",
        title: "Reset Password Error!",
        body: "The password confirmation does not match",
      });
      return;
    }
    cognitoObj.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess(result) {
        const token = result.getIdToken().getJwtToken();
        onSuccess(token, cognitoObj);
      },

      onFailure(err) {
        notify({ type: "error", title: "Login Error!", body: err.message });
        setIsloading(false);
      },
    });
  };

  const onSuccess = (jwt: string, cognitoUser: CognitoUser) => {
    cognitoUser.getUserAttributes(function (err, result) {
      if (err) return console.log(err.message);

      // Find user's 'name' attribute
      const attribute = result?.find(({ Name, Value }) => (Name === "name" ? Value : false));

      login(jwt, attribute?.Value || "N/A");
      navigate("/dashboard");
    });
  };

  return (
    <Group style={{ maxWidth: 400, margin: "50px auto" }}>
      {!isFirstLogin ? (
        <>
          <h5>LOGIN</h5>
          <TextInput label="Email" value={email} onChange={setEmail} />
          <TextInput label="Password" type="password" value={password} onChange={setPassword} />
          <Button text="LOGIN" disabled={isLoading} onClick={authenticate} loading={isLoading} />
        </>
      ) : (
        <>
          <h5>RESET PASSWORD</h5>
          <TextInput
            label="Password"
            type="password"
            onChange={setNewPassword}
            value={newPassword}
            isRequired
          />
          <TextInput
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={setConfirmPassword}
            isRequired
          />
          <Button text="Reset Password" onClick={newPasswordHandler} />
        </>
      )}
    </Group>
  );
};
