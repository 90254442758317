import { CSSProperties } from "react";
import { FormComponentProps } from "../../../types/form-component-props";
import styles from "./TextInput.module.scss";
import { InputContainer } from "../";

type Size = "sm" | "md";

const sizeMap: Record<Size, string> = {
    sm: styles.sm,
    md: styles.md,
};

interface TextInputProps extends FormComponentProps {
    value: string;
    placeholder?: string;
    error?: string;
    readonly?: boolean;
    disabled?: boolean;
    isRequired?: boolean;
    description?: string;
    showErrorMsg?: boolean;
    size?: Size;
    style?: CSSProperties;
    parentStyle?: CSSProperties;
    type?: string;
    label?: string;
    maxLength?: number;
    onKeyUp?: (value: string) => void;
    onChange: (value: string) => void;
}

export const TextInput = (props: TextInputProps) => {
    const {
        value,
        label,
        description,
        error,
        readonly = false,
        disabled = false,
        showErrorMsg = true,
        placeholder,
        size = "md",
        style = {},
        isRequired,
        type = "text",
        parentStyle = {},
        onKeyUp = () => null,
        onChange = () => null,
        maxLength = 255
    } = props;

    const classNames = `${styles.input} ${sizeMap[size]} ${error && styles.hasError}`;

    return (
        <InputContainer
            label={label}
            description={description}
            error={showErrorMsg ? error : ""}
            isRequired={isRequired}
            style={parentStyle}
            render={(ID) => (
                <input
                    id={ID}
                    type={type}
                    readOnly={readonly}
                    disabled={disabled}
                    required={isRequired}
                    maxLength={maxLength}
                    className={classNames}
                    value={value}
                    placeholder={placeholder}
                    style={style}
                    onKeyUp={({ currentTarget }) => onKeyUp && onKeyUp(currentTarget.value)}
                    onChange={({ target }) => onChange(target.value)}
                />
            )}
        />
    );
};
