import { ReactElement, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import { Login } from "../../../pages/Login/Login";

interface PrivateRouteProps {
    children: ReactElement;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const { pathname } = useLocation();
    const { state } = useContext(AuthContext);

    if (!state.loggedIn) {
        return <Login />;
    }

    if (pathname === "/") {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
};
