import { CSSProperties, ReactNode } from "react";
import styles from "./Group.module.scss";

type Size = "xs" | "sm" | "md" | "lg" | "xl";

interface GroupProps {
  gap?: Size;
  direction?: "row" | "column";
  align?: "center";
  justify?: "auto" | "space-between" | "center" | "left";
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void
}

const gapSizeMap: Record<Size, string> = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
};

export const Group = (props: GroupProps) => {
  const {
    gap = "sm",
    align = "",
    direction = "column",
    justify = "auto",
    children,
    className = "",
    style = {},
    onClick = () => null,
  } = props;

  const classNames = `${className} ${styles.group} ${gapSizeMap[gap]}`;
  const inlineStyles = { ...style, flexDirection: direction, justifyContent: justify, alignItems: align };

  return (
    <div className={classNames} onClick={onClick} style={inlineStyles}>
      {children}
    </div>
  );
};
