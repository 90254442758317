import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { AuthContextProvider } from '../../context/auth';
import { Skeleton, SkeletonWithChildren } from "./Skeleton";
import { Login } from '../../pages/Login/Login';
import { ErrorFallback } from '../../pages/ErrorPage/Errors';
import { ReactQueryContextProvider } from '../../context/react-query';
import { NotificationContextProvider } from '../../context/notification';
import { Notifications } from '../../components/Notifications/Notifications';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';
import { Dashboard } from '../../pages/Dashboard/Dashboard';
import { PublicRoute } from './PublicRoute/PublicRoute';
import { Languages } from '../../pages/Languages/Languages';
import { AppTypes } from "../../types/notification-warning-code-response";

function App() {
    return (
        <BrowserRouter>
            <ErrorBoundary
                FallbackComponent={({ error }) => (
                    <SkeletonWithChildren>
                        <ErrorFallback error={error} />
                    </SkeletonWithChildren>
                )}
            >
                <NotificationContextProvider>
                    <ReactQueryContextProvider>
                        <AuthContextProvider>
                            <Routes>
                                <Route path="/" element={<Skeleton />}>

                                    <Route path="" element={
                                        <PublicRoute>
                                            <Login />
                                        </PublicRoute>
                                    } />

                                    <Route
                                        path="dashboard"
                                        element={
                                            <PrivateRoute>
                                                <Dashboard />
                                            </PrivateRoute>
                                        }
                                        index
                                    />

                                    <Route path="/languages">
                                        <Route
                                            path={AppTypes.AGENT}
                                            element={
                                                <PrivateRoute>
                                                    <Languages appType={AppTypes.AGENT} />
                                                </PrivateRoute>
                                            }
                                            index
                                        />
                                        <Route
                                            path={AppTypes.VEHICLE_HEALTH}
                                            element={
                                                <PrivateRoute>
                                                    <Languages appType={AppTypes.VEHICLE_HEALTH} />
                                                </PrivateRoute>
                                            }
                                            index
                                        />
                                    </Route>

                                    {/* <Route path="contact" element={<Contact />} />

                    <Route path="terms-and-conditions" element={<TermsAndConditions />} />

                    <Route path="about" element={<AboutUs />} />

                    <Route path="terms-and-conditions" element={<TermsAndConditions />} />

                    <Route path="about" element={<AboutUs />} />

                    <Route path="terms-and-conditions" element={<TermsAndConditions />} />

                    <Route path="about" element={<AboutUs />} />

                    <Route path="terms-and-conditions" element={<TermsAndConditions />} />

                    <Route path="500" element={<InternalServerError />} />
                    <Route path="*" element={<NotFoundError />} /> */}
                                </Route>
                            </Routes>
                            <Notifications />
                        </AuthContextProvider>
                    </ReactQueryContextProvider>
                </NotificationContextProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
