export interface NotificationWarningCodeBody {
    data: string;
    page?: number;
    type: ElementTypes;
}

export interface NotificationWarningCodeResponse {
    locale: string;
    warningCode: string;
    title: string;
    body: Array<NotificationWarningCodeBody>;
}

export enum ElementTypes {
    CTA = "cta",
    TEXT = "text",
    DISMISS = "dismiss",
}

export enum AppTypes {
    AGENT = "agent",
    VEHICLE_HEALTH = "vehicle-health"
}