import { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import styles from "./Skeleton.module.scss";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";

export const Skeleton = () => (
  <>
    <Navbar />
    <main className={styles.body}>
      <div>{<Outlet />}</div>
    </main>
    <Footer />
  </>
);

export const SkeletonWithChildren = ({ children }: { children: ReactNode }) => (
  <>
    <Navbar />
    <main className={styles.body}>
      <div>{children}</div>
    </main>
    <Footer />
  </>
);
