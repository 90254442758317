import { CSSProperties, ReactNode, useMemo } from "react";
import { randomID } from "../../../utils/random";
import { FormComponentProps } from "../../../types/form-component-props";
import styles from "./InputContainer.module.scss";

export interface InputContainerProps extends FormComponentProps {
  label?: string;
  error?: string;
  isRequired?: boolean;
  description?: string;
  style?: CSSProperties;
  render: (ID: string) => ReactNode;
}

export const InputContainer = (props: InputContainerProps) => {
  const { label, description, error, style = {}, isRequired = false, render } = props;
  const id = useMemo(randomID, []);

  return (
    <div className={styles.container} style={style}>
      {label && (
        <label htmlFor={id}>
          {label} {isRequired && <span className={styles.isRequired}>*</span>}
          {description && <div className="description">{description}</div>}
        </label>
      )}
      {render(id)}
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};
