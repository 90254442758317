import { useContext } from "react";
import { Card } from "./Card";
import styles from "./Dashboard.module.scss";
import { AuthContext } from "../../context/auth";
import { Page } from "../../components/Layout/Page/Page";
import { Group } from "../../components/Layout/Group/Group";
import { SettingsIcon } from "../../components/Icons/Settings";
import { NotificationIcon } from "../../components/Icons/Notification";
import { VehicleHealthIcon } from "../../components/Icons/VehicleHealth";
import { AppTypes } from "../../types/notification-warning-code-response";

export const Dashboard = () => {
  const { state } = useContext(AuthContext);

  return (
    <Page backBtn={false}>
      <Group align="center">
        <div className={styles.title}>
          <Group gap="xs" style={{ textAlign: "center" }}>
            <h4>Hello {state.name}</h4>
            <p className="desc">WELCOME TO THE DASHBOARD</p>
          </Group>
        </div>

        <img src="assets/images/car-top-view.png" alt="Car top view" width="320" title="Car Top View" />

        <Group direction="row" justify="center">
          <Card
            title="Vehicle Health"
            desc="Manage language texts for Vehicle health app"
            link={`/languages/${AppTypes.VEHICLE_HEALTH}`}
            icon={<VehicleHealthIcon />}
          />

          <Card
            title="Onboard Agent"
            desc="Manage language texts for onboard agent"
            link={`/languages/${AppTypes.AGENT}`}
            icon={<NotificationIcon />}
          />

          <Card title="Settings" desc="Personalise your account" link="#" icon={<SettingsIcon />} />
        </Group>
      </Group>
    </Page>
  );
};
